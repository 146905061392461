"use client";
import Hero from "../components/Hero";
import About from "@/components/About";
import Product from "@/components/Product";
import Contact from "@/components/Contact";
import { useEffect } from "react";
import "./globals.css";
import Clients from "@/components/Clients";
import MissionValues from "@/components/MissionValues";
import WhyChooseUs from "@/components/WhyChooseUs";
import Industries from "@/components/Industries";
import Testimonials from "@/components/Testimonial";
import Services from "@/components/Services";

export default function Home() {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  return (
    <div>
      <Hero />
      <About />
      <MissionValues/>
      <Industries/>
      <WhyChooseUs/>
      <Product />
      <Services/>
      <Clients/>
      <Testimonials/>
      <Contact />
    </div>
  );
}

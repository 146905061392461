import Image from "next/image";
import React from "react";

const About = () => {
  return (
    <div id="about" className="min-h-screen w-full flex flex-col py-16 px-6 lg:px-20">
      <h1 className="text-3xl lg:text-5xl flex items-center gap-2 mb-16 font-semibold uppercase border-b-2 border-black w-max mx-3">About us</h1>
      <div className="flex flex-col lg:flex-row">
        <div className="lg:w-1/2 w-full relative items-center flex justify-center my-10">
          <div className="lg:absolute top-0 left-0 w-full lg:w-max items-center flex justify-center">
            <Image width={0} height={0} sizes="100vw" src="/reactor.jpg" alt="About" className="h-[300px] w-[300px] md:h-[400px] md:w-[400px] rounded-md object-cover" />
          </div>
          <div className="absolute hidden lg:flex -bottom-28 right-10">
            <Image width={0} height={0} sizes="100vw" src="/aboutus.avif" alt="About" className="h-[400px] border-white border-8 w-[400px] rounded-md object-cover" />
          </div>
        </div>
        <div className="lg:w-1/2">
          <div className="flex-grow px-4 text-gray-700">
            <h2 className="text-2xl lg:text-5xl mb-10">
              Xpedition Engineers: <br /> <span className="text-2xl lg:text-4xl">Building Your Industrial Future</span>
            </h2>
            <p className="mb-8">
              Xpedition Engineers is a Gujarat, India-based company specializing in turnkey engineering solutions for industrial facilities and projects. Led by Sunil Singh, who boasts over 10 years of experience in the field, our team of experts collaborates closely with clients to deliver
              exceptional results.
            </p>
            <h2 className="text-xl mb-4">Why Choose Xpedition Engineers?</h2>
            <div className="mb-8 grid sm:grid-cols-3 text-center">
              <div className="mb-2 p-2 flex flex-col sm:border-r-2">
                <span className="font-bold mr-2 bg-[#7bffce] rounded-md h-14 flex justify-center items-center py-1 px-2">One-Stop Shop</span> We handle every stage of your project, from conceptual design to final assembly, ensuring streamlined execution.
              </div>
              <div className="mb-2 p-2 flex flex-col sm:border-r-2">
                <span className="font-bold mr-2 bg-[#7bffce] rounded-md h-14 py-1 px-2 flex justify-center items-center">Experience & Expertise</span> Our team possesses in-depth knowledge of various industrial sectors and a proven track record of success.
              </div>
              <div className="mb-2 p-2 flex flex-col">
                <span className="font-bold mr-2 bg-[#7bffce] rounded-md h-14 py-1 px-1 flex justify-center items-center">Commitment to Quality</span> We prioritize safety, reliability, and exceeding your expectations on every project.
              </div>
            </div>
            <p>Xpedition Engineers is your trusted partner for building and maintaining your industrial infrastructure. Contact us today to discuss your specific project requirements and see how we can help you achieve your goals.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

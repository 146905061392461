"use client";
import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import Link from "next/link";
import Image from "next/image";

const Hero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = ["Hero.jpg", "Hero2.jpg"];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [images.length]);
  const h1 = "Innovative engineering";
  const h2 = "construction solutions";
  const h3 = "Delivering top-quality projects with a commitment to safety, sustainability, and excellence in every detail.";
  return (
    <div id="home" className="h-screen w-screen relative">
      <div className="h-screen w-screen relative z-50 overflow-hidden">
          {images.map((src, index) => (
            <Image width={0} height={0} sizes="100vw" 
              key={index}
              src={src}
              alt={`slide ${src}`}
              className={`h-full w-full absolute transition-opacity duration-1000 ${index === currentIndex ? "opacity-100" : "opacity-0"}`}
              style={{
                filter: "brightness(40%)",
              }}
            />
          ))}
        <div className="flex flex-col text-white items-start z-[99999] absolute top-1/4 text-start gap-5 m-5 sm:m-10 md:m-10 md:w-[65vw] lg:min-w-full lg:max-w-full">
          <div className="text-xl sm:text-3xl md:text-4xl xl:text-6xl font-bold md:w-[65vw] lg:min-w-full lg:max-w-full ">
            <div className="overflow-hidden">
              {h1.match(/./g)!.map((char, index) => (
                <span className="animate-text-reveal inline-block [animation-fill-mode:backwards]" key={`${char}-${index}`} style={{ animationDelay: `${index * 0.05}s` }}>
                  {char === " " ? "\u00A0" : char}
                </span>
              ))}
            </div>
            <div className="overflow-hidden">
              {h2.match(/./g)!.map((char, index) => (
                <span className="animate-text-reveal inline-block [animation-fill-mode:backwards]" key={`${char}-${index}`} style={{ animationDelay: `${index * 0.05}s` }}>
                  {char === " " ? "\u00A0" : char}
                </span>
              ))}
            </div>
          </div>
          <div className="text-base sm:w-[400px] overflow-hidden md:text-lg xl:text-3xl lg:min-w-[500px] xl:min-w-[755px]">
            {h3.split(" ").map((word, index) => (
              <span className="animate-text-reveal2 inline-block [animation-fill-mode:backwards] whitespace-nowrap" key={`${word}-${index}`} style={{ animationDelay: `${index * 0.07}s` }}>
                {word}
                {index !== h3.split(" ").length - 1 && "\u00A0"}
              </span>
            ))}
          </div>

          <Link href="#about" className="text-base z-50 cursor-pointer md:text-xl lg:hover:scale-125 duration-300 lg:hover:translate-x-10 hover:bg-[#3b5c50] lg:text-2xl 2xl:text-5xl flex md:w-max gap-4 border-white border-2 p-2 md:p-3 mt-6 justify-around items-center">
            Discover more <FaArrowRight />
          </Link>
        </div>
        <div className="bg-[#000000] z-10 opacity-45 absolute h-full w-[150%] top-0 rotate-45 -translate-x-[60%] -translate-y-[50%] "></div>
        <div className="bg-[#000000] z-10 opacity-45 absolute h-full w-[150%] bottom-0 rotate-45 translate-x-[40%] translate-y-[60%] "></div>
      </div>
    </div>
  );
};

export default Hero;

import Image from "next/image";
import React from "react";

const Product = () => {
  return (
    <div id="product" className="min-h-screen w-full flex flex-col py-16 px-6 lg:px-20">
      <h1 className="text-3xl lg:text-5xl flex items-center gap-2 mb-16 font-semibold uppercase border-b-2 border-black w-max mx-3">Products</h1>
      {/* <h2 className="text-2xl lg:text-3xl mb-10 text-center">Turnkey Industrial Solutions: From Design to Assembly</h2> */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mx-10">
        <div className="w-full text-center flex flex-col gap-5 items-center shadow-xl rounded-lg bg-[#ededed] p-4">
          <Image src={"/blender.jpg"} alt={"blender"} width={0} height={0} sizes="100vw" className="h-64 w-64 object-cover shadow-xl rounded-md" />
          <p className="font-medium text-2xl mr-2  rounded-md h-14 flex justify-center items-center py-1 px-2">INDUSTRIAL BLENDER</p>
          <p className="w-full max-w-72">We design & manufacture custom mixers for specific needs: pressure, temperature, material compatibility. High-speed, low-speed, high-viscosity - we do it all! Get your perfect blend!</p>
        </div>
        <div className="w-full text-center flex flex-col gap-5 items-center shadow-xl rounded-lg bg-[#ededed] p-4">
          <Image src={"/mixer.webp"} alt={"blender"} width={0} height={0} sizes="100vw" className="h-64 w-64 object-cover shadow-xl rounded-md" />
          <p className="font-medium text-2xl mr-2  rounded-md h-14 flex justify-center items-center py-1 px-2">INDUSTRIAL MIXER</p>
          <p className=" w-full max-w-72">Customizable mixers for flawless blending in various industries (food, chemicals, etc.). Heating, cooling, & precise controls for any material. High pressure/vacuum options.</p>
        </div>
        <div className="w-full text-center flex flex-col gap-5 items-center shadow-xl rounded-lg bg-[#ededed] p-4">
          <Image src={"/reactor.webp"} alt={"blender"} width={0} height={0} sizes="100vw" className="h-64 w-64 object-cover shadow-xl rounded-md" />
          <p className="font-medium text-2xl mr-2  rounded-md h-14 flex justify-center items-center py-1 px-2 uppercase">Reactor</p>
          <p className=" w-full max-w-72">From chemicals to pharmaceuticals, these custom reactors create the perfect environment for diverse reactions. High pressure/vacuum, heating/cooling, various materials - no challenge too tough.</p>
        </div>
        <div className="w-full text-center flex flex-col gap-5 items-center shadow-xl rounded-lg bg-[#ededed] p-4">
          <Image src={"/tank.jpeg"} alt={"blender"} width={0} height={0} sizes="100vw" className="h-64 w-64 object-cover shadow-xl rounded-md" />
          <p className="font-medium text-2xl mr-2  rounded-md h-14 flex justify-center items-center py-1 px-2">SILO TANK</p>
          <p className=" w-full max-w-72">Customizable silos (1500mm-6500mm) for perfect storage & transport! Choose stainless steel or aluminum. We offer heating/cooling, level sensors & more. Meet EU standards.</p>
        </div>
        <div className="w-full text-center flex flex-col gap-5 items-center shadow-xl rounded-lg bg-[#ededed] p-4">
          <Image src={"/storagetank.webp"} alt={"blender"} width={0} height={0} sizes="100vw" className="h-64 w-64 object-cover shadow-xl rounded-md" />
          <p className="font-medium text-2xl mr-2  rounded-md h-14 flex justify-center items-center py-1 px-2">STORAGE TANK</p>
          <p className=" w-full max-w-72">Customizable steel & stainless steel tanks for any size & need (production, storage, transport). Meet EU standards & ensure long life. Heating/cooling & level sensors available</p>
        </div>
        <div className="w-full text-center flex flex-col gap-5 items-center shadow-xl rounded-lg bg-[#ededed] p-4">
          <Image src={"/Evaporator.jpg"} alt={"blender"} width={0} height={0} sizes="100vw" className="h-64 w-64 object-cover shadow-xl rounded-md" />
          <p className="text-2xl mr-2 font-medium rounded-md h-14 flex justify-center items-center py-1 uppercase px-2">Evaporator</p>
          <p className=" w-full max-w-72">Thin Film Evaporation: Gentle Separation for Tough Jobs (under 350 characters) Need to separate heat-sensitive liquids? Our thin film evaporators use a gentle process for fast results. Ideal for high-viscosity materials & complex mixtures.</p>
        </div>
      </div>
    </div>
  );
};

export default Product;

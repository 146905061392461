import React from "react";

const MissionValues = () => {
  return (
    <div id="mission-values" className="min-h-screen w-full flex flex-col py-16 px-6 lg:px-20">
      <h1 className="text-2xl lg:text-5xl flex items-center gap-2 mb-16 font-semibold uppercase border-b-2 border-black max-w-max mx-3">
        Our Mission & Values
      </h1>
      <div className="w-full max-w-7xl">
        <section className="mb-16">
          <h2 className="text-2xl lg:text-4xl font-semibold text-teal-700 mb-4">
            Our Mission
          </h2>
          <p className="text-lg lg:text-xl text-gray-700">
            At Xpedition Engineers, our mission is to be the trusted partner for businesses seeking optimal solutions in the design, development, and implementation of industrial equipment. We are dedicated to delivering exceptional engineering expertise, fostering innovation, and consistently exceeding client expectations.
          </p>
        </section>

        <section>
          <h2 className="text-2xl lg:text-4xl font-semibold text-teal-700 mb-4">
            Our Core Values
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="p-6 bg-gray-50 rounded-md shadow-md">
              <p className="text-xl font-semibold text-teal-700 mb-4">
                Innovation
              </p>
              <p className="text-gray-700">
                We embrace creativity and exploration, pushing the boundaries of industrial engineering to find smarter, more efficient solutions.
              </p>
            </div>
            <div className="p-6 bg-gray-50 rounded-md shadow-md">
              <p className="text-xl font-semibold text-teal-700 mb-4">
                Quality & Reliability
              </p>
              <p className="text-gray-700">
                We prioritize high-quality materials and rigorous manufacturing practices to ensure exceptional performance and longevity.
              </p>
            </div>
            <div className="p-6 bg-gray-50 rounded-md shadow-md">
              <p className="text-xl font-semibold text-teal-700 mb-4">
                Client Focus
              </p>
              <p className="text-gray-700">
                Building strong partnerships by understanding and addressing your specific needs is at the core of our approach.
              </p>
            </div>
            <div className="p-6 bg-gray-50 rounded-md shadow-md">
              <p className="text-xl font-semibold text-teal-700 mb-4">
                Efficiency & Sustainability
              </p>
              <p className="text-gray-700">
                We design equipment that meets functional excellence while minimizing environmental impact and promoting sustainability.
              </p>
            </div>
          </div>
        </section>
      </div>
      <p className="mt-16 text-lg text-gray-600">
        Xpedition Engineers is committed to driving your industrial future with innovative and sustainable engineering solutions. Contact us to learn how we can support your next project.
      </p>
    </div>
  );
};

export default MissionValues;

import React from "react";
import { FaCogs, FaLightbulb, FaUsers, FaShieldAlt } from "react-icons/fa";

const WhyChooseUs = () => {
  return (
    <div id="why-choose-us" className="w-full flex flex-col py-20 px-6 lg:px-20">
      <h1 className="text-3xl lg:text-5xl flex items-center gap-2 mb-16 font-semibold uppercase border-b-2 border-black max-w-max mx-3">
        Why Choose Us
      </h1>
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <div className="lg:w-1/2 flex flex-col gap-12">
          <div className="flex items-start mb-8">
            <div className="flex-shrink-0 w-16 h-16 bg-teal-600 text-white rounded-full flex items-center justify-center text-3xl mr-6">
              <FaCogs />
            </div>
            <div>
              <p className="text-3xl font-semibold text-gray-800 mb-4">Brand Identity</p>
              <p className="text-gray-600">
                We establish a strong brand identity that distinguishes your business from competitors. Our unique approach ensures your brand stands out and makes a lasting impression.
              </p>
            </div>
          </div>
          <div className="flex items-start mb-8">
            <div className="flex-shrink-0 w-16 h-16 bg-teal-600 text-white rounded-full flex items-center justify-center text-3xl mr-6">
              <FaLightbulb />
            </div>
            <div>
              <p className="text-3xl font-semibold text-gray-800 mb-4">Unique Design</p>
              <p className="text-gray-600">
                Our designs are tailored to your brand, ensuring uniqueness and a lasting impression. We blend creativity with functionality to create designs that resonate with your audience.
              </p>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 flex flex-col gap-12">
          <div className="flex items-start mb-8">
            <div className="flex-shrink-0 w-16 h-16 bg-teal-600 text-white rounded-full flex items-center justify-center text-3xl mr-6">
              <FaUsers />
            </div>
            <div>
              <p className="text-3xl font-semibold text-gray-800 mb-4">Easy to Customize</p>
              <p className="text-gray-600">
                We provide designs that are easy to customize, allowing you to adapt as your brand evolves. Our solutions are built with flexibility in mind to meet your changing needs.
              </p>
            </div>
          </div>
          <div className="flex items-start mb-8">
            <div className="flex-shrink-0 w-16 h-16 bg-teal-600 text-white rounded-full flex items-center justify-center text-3xl mr-6">
              <FaShieldAlt />
            </div>
            <div>
              <p className="text-3xl font-semibold text-gray-800 mb-4">Customer Support</p>
              <p className="text-gray-600">
                Our dedicated customer support ensures your satisfaction with our services. We are committed to providing timely assistance and addressing your concerns effectively.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;

"use client";
import { useForm} from '@formspree/react';
import React from "react";

const Contact = () => {
  const [state, handleSubmit] = useForm("mvgpreke");
  if (state.succeeded) {
      return <div className='h-screen px-5 items-center'>
          <p className='tracking-widest leading-normal h-full font-thin text-black brightness-150 text-center flex flex-col gap-10 justify-center items-center'>

              <p>Thank you for reaching out to us!</p>

      <p>Your message has been successfully submitted. We appreciate your interest in our services.</p>

      <p>Our team will review your inquiry and get back to you as soon as possible. In the meantime, feel free to explore our website to see our product.</p>

      <p>If you have any urgent inquiries, you can also reach us directly at  <a href="tel:9825810195" className='phonenumber text-black '>+91 9825810195</a> or <a href="mailto:xpedition2022@gmail.com"className='email text-black '>xpedition2022@gmail.com</a></p>

      <p>We look forward to the opportunity to work with you!</p></p>
      </div>;
  }
  return (
    <div id="contact" className=" min-h-screen h-max px-3 py-10 md:py-24  sm:px-16 md:px-32 ">
      <h1 className="text-3xl lg:text-5xl flex items-center gap-2 mb-16 font-semibold uppercase border-b-2 border-black w-max mx-3">Contact us</h1>
      <div className="py-10 px-3 md:pr-10 h-max w-full bg-[#ededed] bg-blend-overlay bg-opacity-95 flex flex-col lg:flex-row justify-between items-center rounded-xl shadow-2xl">
        <div className="md:p-10">
          <ul className="mb-6 md:mb-0 flex flex-col gap-12">
            <li className="flex">
              <div className="flex h-10 w-10 items-center justify-center rounded bg-[#ededed] text-black">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                  <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                  <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                </svg>
              </div>
              <div className="ml-4 mb-4">
                <p className="mb-2 text-lg font-medium leading-6 text-gray-900 ">Our Address</p>
                <p className="text-black uppercase">plot no. 80, Yogi estate-1,</p>
                <p className="text-black uppercase">3rd raw,ankleshwar-393002,</p>
                <p className="text-black uppercase">dist.bharuch, Gujarat</p>
              </div>
            </li>
            <li className="flex">
              <div className="flex h-10 w-10 items-center justify-center rounded bg-[#ededed] text-black">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                  <path d="M15 7a2 2 0 0 1 2 2"></path>
                  <path d="M15 3a6 6 0 0 1 6 6"></path>
                </svg>
              </div>
              <div className="ml-4 mb-4">
                <p className="mb-2 text-lg font-medium leading-6 text-gray-900 ">Contact</p>
                <p>
                  <a href="tel:9825810195" className="text-black ">
                    Mobile: +91 9825810195
                  </a>
                </p>
                <p>
                  <a href="mailto:xpedition2022@gmail.com" className="text-black ">
                    Mail: xpedition2022@gmail.com
                  </a>
                </p>
              </div>
            </li>
            <li className="flex">
              <div className="flex h-10 w-10 items-center justify-center rounded bg-[#ededed] text-black">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6">
                  <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                  <path d="M12 7v5l3 3"></path>
                </svg>
              </div>
              <div className="ml-4 mb-4">
                <p className="mb-2 text-lg font-medium leading-6 text-gray-900 ">Working hours</p>
                <p className="text-black ">Monday - Friday: 08:00 - 17:00</p>
                <p className="text-black ">Saturday &amp; Sunday: 08:00 - 12:00</p>
              </div>
            </li>
          </ul>
        </div>
        <div className="items-center lg:w-1/2 min-h-screen h-max">
          <form  onSubmit={handleSubmit} className=" gap-12 grid grid-cols-2 my-10 lg:mt-28 mx-4">
            <div className="col-span-2">
              <label htmlFor="name" className="block mb-2 text-sm font-medium text-black ">
                Your Name
              </label>
              <input type="name" name="name" id="name" autoComplete="off" className=" border border-black p-3 w-full outline-black text-sm text-black  shadow-sm " placeholder="First and last name" required style={{ background: "transparent" }} />
            </div>
            <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-black ">
                Your Email
              </label>
              <input type="email" id="email" name="email" autoComplete="off" className=" border border-black p-3 w-full outline-black text-sm text-black  shadow-sm " placeholder="name@.com" required style={{ background: "transparent" }} />
            </div>
            <div>
              <label htmlFor="phone" className="block mb-2 text-sm font-medium text-black ">
                Your Number
              </label>
              <input type="tel" id="phone" name="Contact number" autoComplete="off" pattern="(\+)?(0|91)?[6-9]\d{9}" className=" border border-black p-3 w-full outline-black text-sm text-black  shadow-sm " placeholder="Your contact number" required style={{ background: "transparent" }} />
            </div>
            <div className="col-span-2">
              <label htmlFor="subject" className="block mb-2 text-sm font-medium text-black ">
                Subject
              </label>
              <input type="text" id="subject" name="subject" autoComplete="off" className="block border border-black p-3 w-full outline-black text-sm text-black shadow-sm" placeholder="Let us know how we can help you" required style={{ background: "transparent" }} />
            </div>
            <div className="col-span-2">
              <label htmlFor="message" className="block mb-2 text-sm font-medium text-black  ">
                Your message
              </label>
              <textarea id="message" name="message" rows={6} className="block p-2.5 w-full  outline-black text-sm text-black shadow-sm border border-black   " placeholder="Leave a comment..." style={{ background: "transparent" }}></textarea>
            </div>
            <button type="submit" className="py-3 col-span-2 md:col-span-1 px-5 text-sm font-medium text-center text-black bg-[#d2d2d2] sm:w-fit focus:ring-4 focus:outline-none  ">
              Send message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;

{
  /* <div className='min-h-screen h-max w-screen flex flex-col lg:px-20 my-20'>
    <h1 className='text-3xl lg:text-5xl flex items-center gap-2 mb-3 font-semibold uppercase border-b-2 border-black w-max mx-3'>About us</h1>
      <div>
        <div>
       <p>feel free to contact us anytime. we wil lget back to you as soon as we can!</p>
        </div>
        <div>
            <p>Info</p>
            <p>123@gmail.com</p>
            <p>njmber</p>
            <p>clock</p>
        </div>
      </div>
    </div> */
}

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules";

const testimonials = [
  {
    review:
      "Xpedition Engineers delivered exceptional service. Their attention to detail and dedication to quality made all the difference in our project. We are thoroughly impressed!",
    name: "Prakash Chemical",
    position: "CEO",
  },
  {
    review:
      "The team at Xpedition Engineers went above and beyond to ensure our needs were met. Their expertise in industrial engineering is unparalleled, and we couldn't be happier with the results.",
    name: "Chloride India",
    position: "Managing Director",
  },
  {
    review:
      "From start to finish, Xpedition Engineers provided outstanding support and guidance. Their ability to manage complex projects with ease is truly commendable.",
    name: "Mahipal Chemical",
    position: "Operations Manager",
  },
  {
    review:
      "We are extremely satisfied with the work done by Xpedition Engineers. They understood our requirements perfectly and delivered a solution that exceeded our expectations.",
    name: "Paras Pharma",
    position: "Project Lead",
  },
  {
    review:
      "Xpedition Engineers' professionalism and commitment to excellence are evident in every aspect of their work. We highly recommend their services.",
    name: "Vinyl Products",
    position: "Technical Director",
  },
  {
    review:
      "Working with Xpedition Engineers was a great experience. Their team is knowledgeable, responsive, and always willing to go the extra mile to ensure customer satisfaction.",
    name: "Sigachi",
    position: "Procurement Head",
  },
];

const Testimonials = () => {
  return (
    <div className="h-max py-24 sm:px-16 md:px-32 flex flex-col tracking-widest">
      <div className="w-full flex flex-col mb-5 md:mb-12 gap-5 text-center">
        
      <h1 className="text-3xl lg:text-5xl flex gap-2 mb-16 font-semibold text-center uppercase border-b-2 border-black max-w-max mx-3">Testimonials</h1>
        <div className="text-xl uppercase font-semibold">Hear the Cheers from Our Esteemed Partnerships!</div>
      </div>
      <div className="w-full justify-center ">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.50": {
              slidesPerView: 2.5,
              spaceBetween: 30,
            },
          }}
          coverflowEffect={{
            rotate: 40,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={{ clickable: true }}
          modules={[Autoplay, EffectCoverflow, Pagination]}
          className="mySwiper"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="h-[400px] w-96 my-8 mx-5 px-3 text-center flex flex-col justify-center items-center bg-white shadow-lg rounded-lg">
                <div className="flex justify-center items-center gap-4 py-10 h-20 text-2xl text-yellow-500">
                  <p>&#9733; &#9733; &#9733; &#9733; &#9733;</p>
                </div>
                <div className="pt-5 pb-10 text-sm px-5 text-gray-700">
                  {testimonial.review}
                </div>
                <div className="flex flex-col justify-center items-center">
                  <div className="uppercase text-xl -translate-y-5 p-3 bg-teal-800 text-white font-semibold w-max rounded-md">
                    {testimonial.name}
                  </div>
                  <div className="uppercase text-sm -translate-y-5 text-teal-700">
                    {testimonial.position}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonials;

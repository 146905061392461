import React from "react";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import Image from "next/image";

const Clients = () => {
  const clients = ['1.png','2.svg','3.svg','4.svg','5.svg','6.png','7.jpg','8.png'];
  return (
    <div className="w-screen text-center flex flex-col gap-5 py-10 md:py-24">
      <h1 className="text-3xl lg:text-5xl flex items-center gap-2 mb-3 font-semibold uppercase border-b-2 border-black w-max mx-3 sm:mx-16 md:mx-28 ">Our Clients</h1>

      <div className="w-screen h-max">
        <Swiper
          spaceBetween={30}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            "@0.00": {
              slidesPerView: 3,
              spaceBetween: 0,
            },
            "@01": {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            "@1.50": {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          modules={[Autoplay]}
          className="mySwiper w-full h-full flex justify-center items-center "
        >
          {clients.map((c, index: number) => {
            return (
              <SwiperSlide key={index}>
                <div className="cursor-pointer pointer-events-none flex flex-col justify-center gap-3 w-[100px] h-[60px] mt-10 md:h-[150px] md:w-[200px]  overflow-hidden px-3">
                  <Image src={`/clients/${c}`} alt="company logo" height={100} width={100} className="w-full h-full bg-cover object-center" />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default Clients;

import React from "react";

const Industries = () => {
  return (
    <div id="industries" className="w-full flex flex-col py-16 px-6 lg:px-20">
      <h1 className="text-3xl lg:text-5xl flex items-center gap-2 mb-16 font-semibold uppercase border-b-2 border-black max-w-max mx-3">
        Industries We Serve
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <div className="group relative p-6 bg-gray-50 rounded-md shadow-md overflow-hidden hover:bg-teal-500 transition-all duration-300">
          <p className="text-2xl font-semibold text-teal-700 group-hover:text-white mb-4 transition-colors duration-300">Pharmaceuticals</p>
          <p className="text-gray-700 group-hover:text-white transition-colors duration-300">
            Cutting-edge solutions for pharmaceutical manufacturing, ensuring compliance with industry standards.
          </p>
        </div>
        <div className="group relative p-6 bg-gray-50 rounded-md shadow-md overflow-hidden hover:bg-teal-500 transition-all duration-300">
          <p className="text-2xl font-semibold text-teal-700 group-hover:text-white mb-4 transition-colors duration-300">Chemicals</p>
          <p className="text-gray-700 group-hover:text-white transition-colors duration-300">
            Specializing in chemical processing equipment that meets the rigorous demands of the industry.
          </p>
        </div>
        <div className="group relative p-6 bg-gray-50 rounded-md shadow-md overflow-hidden hover:bg-teal-500 transition-all duration-300">
          <p className="text-2xl font-semibold text-teal-700 group-hover:text-white mb-4 transition-colors duration-300">Food & Beverage</p>
          <p className="text-gray-700 group-hover:text-white transition-colors duration-300">
            Engineering reliable and efficient solutions for the food and beverage industry.
          </p>
        </div>
        <div className="group relative p-6 bg-gray-50 rounded-md shadow-md overflow-hidden hover:bg-teal-500 transition-all duration-300">
          <p className="text-2xl font-semibold text-teal-700 group-hover:text-white mb-4 transition-colors duration-300">Oil & Gas</p>
          <p className="text-gray-700 group-hover:text-white transition-colors duration-300">
            Delivering robust engineering solutions for the oil and gas sector, tailored to meet industry challenges.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Industries;

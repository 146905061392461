import React from "react";
import { FaRocket, FaTools, FaHandshake, FaRegCalendarAlt } from "react-icons/fa";

const Services = () => {
  return (
    <div id="services" className="w-full py-20 px-10 lg:px-20">
      <h1 className="text-3xl lg:text-5xl flex items-center gap-2 mb-16 font-semibold uppercase border-b-2 border-black max-w-max mx-3">
        Our Services
      </h1>
      <div className="relative lg:mx-24">
        <div className="absolute inset-0 border-l-4 border-teal-500"></div>
        <div className="space-y-12 lg:space-y-16">
          <div className="flex items-start relative pl-8 lg:pl-12">
            <div className="flex-shrink-0 w-12 h-12 bg-teal-600 text-white rounded-full flex items-center justify-center text-2xl absolute -left-6 lg:-left-8">
              <FaRocket />
            </div>
            <div className="ml-8 lg:ml-16">
              <p className="text-3xl font-semibold text-gray-800 mb-4">Project Kickoff</p>
              <p className="text-gray-600">
                We begin with a comprehensive analysis of your needs, setting clear objectives and planning every aspect of the project to ensure success.
              </p>
            </div>
          </div>
          <div className="flex items-start relative pl-8 lg:pl-12">
            <div className="flex-shrink-0 w-12 h-12 bg-teal-600 text-white rounded-full flex items-center justify-center text-2xl absolute -left-6 lg:-left-8">
              <FaTools />
            </div>
            <div className="ml-8 lg:ml-16">
              <p className="text-3xl font-semibold text-gray-800 mb-4">Design & Engineering</p>
              <p className="text-gray-600">
                Our team of experts crafts innovative solutions, applying advanced engineering techniques to meet your specific requirements.
              </p>
            </div>
          </div>
          <div className="flex items-start relative pl-8 lg:pl-12">
            <div className="flex-shrink-0 w-12 h-12 bg-teal-600 text-white rounded-full flex items-center justify-center text-2xl absolute -left-6 lg:-left-8">
              <FaHandshake />
            </div>
            <div className="ml-8 lg:ml-16">
              <p className="text-3xl font-semibold text-gray-800 mb-4">Turnkey Solutions</p>
              <p className="text-gray-600">
                We handle every stage from design to installation, providing a seamless turnkey solution that ensures your project is completed efficiently and effectively.
              </p>
            </div>
          </div>
          <div className="flex items-start relative pl-8 lg:pl-12">
            <div className="flex-shrink-0 w-12 h-12 bg-teal-600 text-white rounded-full flex items-center justify-center text-2xl absolute -left-6 lg:-left-8">
              <FaRegCalendarAlt />
            </div>
            <div className="ml-8 lg:ml-16">
              <p className="text-3xl font-semibold text-gray-800 mb-4">Ongoing Support</p>
              <p className="text-gray-600">
                We offer continued support and maintenance to ensure your systems run smoothly and any issues are promptly addressed.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
